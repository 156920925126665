var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"canvas-wrapper position-relative"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('Particles',{ref:"Particles",attrs:{"initImageUrl":"/img/cata/blank.png"}})],1),(true)?_c('div',{staticClass:"milestone-list-wrapper"},[_c('ul',{staticClass:"milestone-lists"},_vm._l((_vm.comblineMilestoneWithPosition),function(ref){
        var id = ref.id;
        var top = ref.top;
        var left = ref.left;
        var description = ref.description;
        var dl = ref.descriptionLeft;
        var dt = ref.descriptionTop;
        var date = ref.date;
        var images = ref.images;
        var scale = ref.scale;
        var imgDirection = ref.imgDirection;
return _c('li',{key:id,staticClass:"milestone-list position-absolute animate__animated animate__zoomIn",style:({
          top: top,
          left: left,
          transform: ("scale(" + scale + ")")
        }),on:{"click":function($event){return _vm.clickEvent(id)}}},[_c('div',{staticClass:"milestone-thumbnail position-relative"},[_c('carousel',{attrs:{"per-page":1,"loop":true}},_vm._l((images),function(img,index){return _c('slide',{key:'milestone_image_' + index},[_c('img',{staticClass:"milestone-image",class:{portrait: imgDirection === 'portrait' },attrs:{"src":img,"alt":"milestone-image"}})])}),1),_c('article',{staticClass:"description position-absolute",style:({ left: dl, top: dt })},[_c('h3',{staticClass:"mb-2"},[_c('b',[_vm._v(_vm._s(date))])]),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(description))])]),_vm._m(0,true)],1)])}),0)]):_vm._e(),_c('div',{staticClass:"canvas-block"},[_c('canvas',{ref:"js-milestone-canvas",style:({ width: _vm.width + 'px', height: _vm.height + 'px' })})]),_c('HUD',{ref:"HUD",attrs:{"drawText":true,"drawInnerCircle":false,"translate":{x: -150,y: 0},"globalScaleRef":800}}),_c('div',{ref:"draggableArea",staticClass:"draggableArea",on:{"mousedown":_vm.mousedown,"mouseleave":_vm.mouseup,"mouseup":_vm.mouseup,"mousemove":_vm.mousemove,"touchstart":_vm.startDrag,"touchmove":_vm.dragMove,"touchend":_vm.endDrag}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"envelope position-absolute w-100"},[_c('div',{staticClass:"cross-line w-100 h-100"})])}]

export { render, staticRenderFns }